import React, { Suspense } from "react";

import { ContactPanelTabIds } from "@hd/contact-panel/types/contact-panel-tab-ids";
import { useContactPanel } from "@hd/contact-panel/useContactPanel";
import HdLoader from "@hd/ui-kit/hd-loader/HdLoader";
import HdModal from "@hd/ui-kit/hd-modal/HdModal";

import { useNewConversationModalStore } from "./new-conversation-modal.store";

const NewConversationModalContent = React.lazy(
  () => import("./components/NewConversationModalContent")
);
function NewConversationModal() {
  const {
    isOpen: isOpenNewConversationModal,
    close: closeNewConversationModal,
  } = useNewConversationModalStore();

  const { open: openContactPanel } = useContactPanel();

  return (
    <HdModal
      backdrop={true}
      isOpen={isOpenNewConversationModal}
      closeOnEsc={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          closeNewConversationModal();
        }
      }}
      // closeOnClickOutside={false}
      className="p-2 bg-white w-[860px] min-h-[85vh] flex flex-col"
    >
      <Suspense fallback={<HdLoader></HdLoader>}>
        <NewConversationModalContent
          openContactConversationPanel={(contactId, companyId) => {
            openContactPanel(
              contactId,
              companyId,
              ContactPanelTabIds.CONVERSATION
            );
          }}
        ></NewConversationModalContent>
      </Suspense>
    </HdModal>
  );
}

export default NewConversationModal;
